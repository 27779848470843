<!-- 基因数据库管理系统 1-->
<template>
  <div class="gene">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'基因数据库管理系统'" />
    <Business :business="business" />
    <!-- 应用场景 -->
    <div class="applications-container">
      <Subhead :enVal="'Applications  '" :znVal="'应用场景'" />
      <div class="app-text">{{ appText }}</div>
    </div>
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import Subhead from "./components/Subheading.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    Subhead
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner4.png"),
      business: {
        text: "生命科学时代，人类已经越来越认识到生物遗传基因数据对国民健康、保护生物多样性和生物产业发展具有举足轻重的作用。随着基因测序技术的不断突破，基因测序成本直线下降，为生命科学领域带来了重大变革，基因资源的开发利用将占据未来全球生物产业链发展的战略制高点，而构建基因数据库是是对基因数据进行有效保存、管理和合理利用的最好管理方式。建设基因数据库管理系统可以提高对基因数据的储存、分析和管理能力，促进基因及数据资源共享利用，有利于维护生物信息安全，增强在生命科学大数据时代的科技创新能力。",
        Imgs: [
          {
            url: require("@/assets/images/cases/content/gene01.png")
          }
        ]
      },
      appText: "基因数据库管理系统具有基因数据的提交、展示、搜索与查询基因组信息功能，系统可定制生物基因数据的提交、审核、发布工作流管理，支持建立对提交的数据建立 审核流程，确保数据信息质量和数据规范、来源合法。系统支持建立严格的权限管理机制，对资源数据库的信息数据进行分级管理，并设置多级访问权限。数据格式包括 FASTQ、FASTA、GFF等,支持根据物种名称、基因名称、基因ID、基因位置进行搜索,可检索基因的具体信息,包括基因位置、类型、长度、序列、基因结构、基因注释信息。对检索数据进行可视化展示,支持通过JBrowse界面展示搜索结果。除了基因组数据外,系统支持扩展DNA变异数据库、基因表达数据库、QTL性状数据库,以及基于以上的整合数据库,形成一个多组学知识相关联的数据库。在建设中山大学南方海洋科学与工程广东省实验室（珠海）海洋生物基因资源数据库管理系统中,数据库采用MySQL关系型数据库,科学设计表间关联,降低数据的冗余，保证数据的一致性和完整性，通过共享服务网站实现了海洋生物基因资源数据共享。"

    }
  }
}
</script>

<style lang="less" scoped>
</style>